import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { shiftSchema } from "@src/appV2/Shifts/Shift/types";
import { type UseQueryResult } from "@tanstack/react-query";
import { format } from "date-fns";
import { z } from "zod";

const agentShiftRequestSchema = z.object({
  dateFilter: z.object({
    start: z.string(),
    end: z.string(),
  }),
  tmz: z.string(),
  groupByDate: z.boolean(),
});

const agentShiftResponseSchema = z.object({
  agentShifts: z.array(
    shiftSchema.extend({
      agent: z.object({
        name: z.string(),
        _id: z.string(),
        email: z.string(),
        agentId: z.string(),
        phone: z.string(),
        requirements: z.array(
          // This is similar to `agentProfileResponseSchema`. Maybe these are the same.
          z.object({
            _id: z.string(),
            expires: z.string().nullable().optional(),
            updatedAt: z.string().optional(),
            requirement: z.string().optional(),
          })
        ),
      }),
    })
  ),
});

export type AgentShiftRequest = z.infer<typeof agentShiftRequestSchema>;
export type AgentShiftCountResponse = z.infer<typeof agentShiftResponseSchema>;

export interface AgentShiftCountParams {
  startDate: Date;
  endDate: Date;
  tmz: string;
  groupByDate: boolean;
}

export const GET_AGENT_SHIFTS_PATH = "/calendar/agent";
export const GET_AGENT_SHIFTS_URL = `${environmentConfig.REACT_APP_BFF_URL}${GET_AGENT_SHIFTS_PATH}`;

/**
 * CAUTION: Underlying endpoint returns shifts in random chronological order.
 * Sometimes it's oldest to newest, sometimes newest to oldest.
 */
export function useAgentShifts(
  params: AgentShiftCountParams,
  options: UseGetQueryOptions<AgentShiftCountResponse> = {}
): UseQueryResult<AgentShiftCountResponse> {
  const { startDate, endDate, groupByDate, tmz } = params;

  const queryParams: AgentShiftRequest = {
    dateFilter: {
      start: format(startDate, "yyyy-MM-dd"),
      end: format(endDate, "yyyy-MM-dd"),
    },
    tmz,
    groupByDate,
  };

  return useGetQuery({
    url: GET_AGENT_SHIFTS_URL,
    queryParams,
    requestSchema: agentShiftRequestSchema,
    responseSchema: agentShiftResponseSchema,
    meta: {
      userErrorMessage: "Something went wrong while loading shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_SHIFTS_FAILURE,
    },
    ...options,
  });
}
