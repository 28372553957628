import { isDefined } from "@clipboard-health/util-ts";

import {
  BookabilityDecision,
  type BookabilityStatusItem,
  type BookabilityUnmetCriteria,
} from "./types";
/**
 * Simple helper for determining if a shift is blocked due to a specific unmet criteria
 * Reduces boilerplate
 */
export function hasUnmetBookabilityCriteria(
  bookabilityStatus: BookabilityStatusItem | undefined,
  unmetCriteria: BookabilityUnmetCriteria[]
) {
  if (!isDefined(bookabilityStatus)) {
    return false;
  }

  if (bookabilityStatus.attributes.bookability.decision === BookabilityDecision.BLOCKED) {
    return bookabilityStatus.attributes.bookability.unmetCriteria.some((criteria) =>
      unmetCriteria.includes(criteria)
    );
  }

  return false;
}
