import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { baseFacilityNoteSchema, facilitySchema } from "@src/appV2/Facilities/types";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { shiftSchema } from "@src/appV2/Shifts/Shift/types";
import { z } from "zod";

const openShiftsRequestSchema = z
  .object({
    dateFilter: z
      .object({
        start: z.string(),
        end: z.string(),
      })
      .optional(),
    coordinates: z.tuple([z.number(), z.number()]).optional(),
    distance: z.number().optional(),
    qualification: z.string().optional(),
    facilityId: z.string().optional(),
    specialities: z
      .object({
        hasSedationExperience: z.boolean(),
        hasTrayAssemblyExperience: z.boolean(),
      })
      .optional(),
    id: z.array(z.string()).optional(),
    // MHR = maximum hours restrictions, a.k.a can't book more than X hours per week / Y consecutive hours.
    // When this is set to true we don't filter away such shifts even if they aren't bookable
    includeMHRRestrictedShifts: z.boolean().optional(),
  })
  .optional();

const openShiftsResponseSchema = z.object({
  openAgentShifts: z.array(
    shiftSchema.extend({
      filtered: z.boolean().optional(),
      // facilityNotes actually comes within the facility object
      facilityNotes: z.undefined(),
      facility: facilitySchema.extend({
        facilityNotes: z.array(
          baseFacilityNoteSchema.pick({
            category: true,
            categoryTextVisibleToWorker: true,
            identifier: true,
            isVisibleToWorker: true,
            note: true,
            noteId: true,
            updatedAt: true,
          })
        ),
        rating: z.object({
          count: z.number(),
          value: z.number(),
        }),
      }),
    })
  ),
});

export type OpenShiftsRequestParams = z.infer<typeof openShiftsRequestSchema>;
export type OpenShiftsResponse = z.infer<typeof openShiftsResponseSchema>;

export type OpenShiftsQueryOptions = UseGetQueryOptions<OpenShiftsResponse>;

export const GET_OPEN_SHIFTS_PATH = "/calendar/openShifts";
export const GET_OPEN_SHIFTS_URL = `${environmentConfig.REACT_APP_BFF_URL}${GET_OPEN_SHIFTS_PATH}`;

export function useOpenShifts(
  params?: OpenShiftsRequestParams,
  options: OpenShiftsQueryOptions = {}
) {
  return useGetQuery({
    url: GET_OPEN_SHIFTS_URL,
    queryParams: params,
    requestSchema: openShiftsRequestSchema,
    responseSchema: openShiftsResponseSchema,
    meta: {
      userErrorMessage: "Something went wrong while loading shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_OPEN_SHIFT_BY_DATE_FAILURE,
    },
    ...options,
  });
}
